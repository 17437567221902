@font-face {
  font-family: 'Museo-bd';
  src: url('../../assets/fonts/museoSans/MuseoSans_700.otf');
}

.admin-nav {
  .navbar {
    background: linear-gradient(174.23deg, #060954 51.36%, #1cb4ae 117.17%);
    mix-blend-mode: normal;
    padding: 20px 0 0;
    @media (max-width: 770px) {
      padding: 0 30px;
    }
    .navbar-brand {
      img {
        width: 50px;
        position: relative;
        bottom: 10px;
        @media (max-width: 770px) {
          bottom: 0;
        }
      }
    }

    .navbar-collapse {
      @media (max-width: 770px) {
        margin-top: 15px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #fff;
          font-size: 12px;
          &.active {
            border-bottom: 3px solid #fff;
            @media (max-width: 900px) {
              border-bottom: none;
            }
          }
        }
        padding: 0 10px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        @media (max-width: 770px) {
          padding: 0;
        }
        &:hover {
          cursor: pointer;
        }
        a {
          padding: 13px 8px 26px 8px;
          border-bottom: 3px solid transparent;
          &:hover {
            border-bottom: 3px solid #fff;
          }
        }
      }
    }
    .navbar-nav.drop-down {
      @media (max-width: 900px) {
        &.align-items-center {
          align-items: flex-start !important;
        }
      }
      .nav-item {
        padding: 0;
        text-transform: none;
        a {
          padding: 0;
          border-bottom: 3px solid transparent;
          &:hover {
            border-bottom: 3px solid transparent;
          }
        }
        .dropdown-menu {
          a {
            padding: 15px;
          }
          @media (max-width: 770px) {
            margin-bottom: 20px;
          }
        }
        img {
          width: 40px;
          border-radius: 50%;
        }
      }
      .with-img {
        background: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        img {
          width: 50px;
          height: 50px;
          padding: 2px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}
.admin-footer {
  background: #f5f6f7;
  color: #b9b9b9;
  font-size: 12px;
  @media (max-width: 770px) {
    font-size: 11px;
  }
  a {
    color: #b9b9b9;
  }
  .footer-links {
    ul {
      li {
        line-height: 21px;
        &:hover {
          cursor: pointer;
          color: lighten($color: #b9b9b9, $amount: 20);
        }
        &:nth-child(1) {
          border-right: 1px solid #b9b9b9;
        }
      }
    }
  }
}
.admin {
  color: #484848;
  background: #f5f6f7;

  .font-bold {
    font-family: 'Museo-bd' !important;
  }
  .dash-cards__wrapper {
    .card {
      background-color: #060954;
      flex-direction: inherit;
      border-radius: 6px;
      @media (max-width: 770px) {
        margin-bottom: 20px;
      }
      img {
        width: 50px;
      }
      .c-text {
        h6 {
          font-size: 32px;
          line-height: 37px;
          display: flex;
          align-items: center;
          color: #ffffff;
        }
        p {
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
        }
      }
    }
    .card.special {
      background: linear-gradient(90deg, #060954 0%, #1cb4ae 100%);
    }
  }
  .each-course {
    color: #484848;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(82, 82, 82, 0.12);
    &:hover {
      text-decoration: dashed;
    }
    img {
      border-radius: 5px 5px 0 0;
      // max-width:100%;
      height: 152px;
      object-fit: cover;
      // width:215px;
    }
  }

  .card-table {
    .table {
      .profile {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      thead th {
        border: none !important;
      }
      td {
        vertical-align: middle;
        border: none !important;
        color: #484848;
        padding: 1rem;

        span,
        p {
          margin-bottom: 0 !important;
        }
      }
    }
    table thead tr th {
      border-color: transparent !important;
      padding-bottom: 0;
      text-transform: uppercase;
      position: relative;
      font-size: 14px !important;
      color: #333333;
    }
    table tbody tr {
      background-color: #fff;
      outline: none;
      border-radius: 3px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      height: 60px !important;
    }

    table tbody tr td {
      border: transparent;
      border-radius: 2px;
      vertical-align: middle;
    }
    table {
      border-collapse: separate !important;
      border-spacing: 0 10px;
    }
  }
  .list-nav {
    .numbers {
      margin: 0 1.5rem;
      background: white;
      padding: 0.5rem 0.5rem;
      border-radius: 20px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
      span {
        margin: 0.8rem;
        @media (max-width: 770px) {
          margin: 0.5rem;
        }
      }
    }
    .prev,
    .next {
      padding: 0.3rem 0.9rem;
      background: linear-gradient(174.23deg, #060954 51.36%, #1cb4ae 117.17%);
      color: white;
      border-radius: 50%;
    }
    .numbers .active {
      background: linear-gradient(174.23deg, #060954 51.36%, #1cb4ae 117.17%);
      padding: 0.6rem 0.8rem;
      color: white;
      border-radius: 50%;
    }
  }
  .text-grey {
    color: #b9b9b9 !important;
  }
  .admin-create {
    .form-control {
      padding: 0.375rem 0.75rem;
    }
    .text-grey {
      color: #979797 !important;
    }
    form {
      textarea {
        resize: none;
      }
      .btn {
        width: auto !important;
      }
    }
    .submit-btn {
      padding: 0.8rem 3rem !important;
      font-size: 16px !important;
    }
  }
  .file-upload {
    cursor: pointer;
    background: white;
    border-radius: 40px 0 0 40px;
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.8rem;
    font-size: 14px;
    color: #484848;
    font-weight: 300;
    .upload-btn {
      color: white;
      background: #60cbbe;
      font-size: 16px;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 1rem 2rem;
      border-radius: 5px;
    }
    #file-picker {
      display: none;
    }
  }
  .course-header {
    background: linear-gradient(174.23deg, #060954 51.36%, #1cb4ae 117.17%);
    .playlist-header {
      background: #242424;
      opacity: 0.4;
      border-radius: 6px;
      opacity: 0.4;
      color: white;
    }
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        color: white;
        font-size: 14px;
        opacity: 0.7;
        padding: 1rem;
      }
    }
    .fa-play {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }
  .setting_wrapper {
    .edit-click {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 0px 0px 6px 6px;
    }
    .profile-img {
      background: #515387;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      img {
        width: 150px;
        height: 150px;
        padding: 8px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .nav-pills .nav-link {
      color: #c9c9c9;
      font-size: 18px;
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #484848;
      background-color: transparent;
    }
    .modal-body {
      .profile {
        width: 150px;
        height: 150px;
        position: relative;
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      .file-picker {
        position: absolute;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        .inputfile {
          display: none;
        }
      }
    }
    .modal {
      p {
        color: #484848;
      }
      select {
        font-size: 14px;
        color: #999999;
      }
      .common-input {
        background: #fcfcfc;
        border: 1px solid #ebebeb;
        border-radius: 25px;
        padding: 0.6rem 1rem;
        option {
          font-size: 14px;
          color: #999999;
        }
        &::placeholder {
          font-size: 14px;
          color: #999999;
        }
        &::-moz-placeholder {
          font-size: 14px;
          color: #999999;
        }
        &:focus,
        input:focus,
        .btn:active,
        .btn:focus,
        select {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
    .badge {
      border-radius: 20px;
      font-size: 9px;
      border-radius: 52px;
      font-family: 'museo-lt';
      color: white;
      &.completed {
        background: #3cd498;
      }
      &.ongoing {
        background: #eebf17;
      }
      &.incomplete {
        background: #f31743;
      }
    }
    #pills-ResultsCertificates {
      .record {
        border-radius: 50px;
        background: #f5f6f7;
      }
      .fa-trophy {
        color: black;
        -webkit-text-fill-color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
      }
    }
  }
}

.iframe-scroll {
  width: 400px;
  position: relative;
  height: 157px;
  iframe {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
  }
}
