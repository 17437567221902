
.btn {
    padding: 0.375rem 1rem !important;
    border-radius: 20px;
    font-size: 12px;
  }
  .btn-green{
    background: #60CBBE;
    color: white;
    transition: all ease .3s;
    &:hover{
      transform: translate(0px, 5px);
        color: white;
    }
  }
  .btn-outline-green{
    color: #60CBBE;
    border: #60CBBE solid 1px;
    background: white;
    &:hover{
      background: lighten($color: #60cbbe, $amount: 10%);
      color: white;
    }
  }
  .btn-outline-red{
    color:red;
    border:red solid 1px;
    background: white;
    &:hover{
      background: lighten($color:red, $amount: 10%);
      color: white;
    }
  }
  .btn-sm{
    padding: .1rem .7rem !important;
    height: 23px;
    font-size: 12px;
  }