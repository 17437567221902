@import './styles/button.scss';
@font-face {
  font-family: 'radikal';
  src: url('./assets/fonts/Radikal-Regular.ttf');
}

@font-face {
  font-family: 'publico';
  src: url('./assets/fonts/publico-banner-web/Publico-Banner-Web-Bold-Regular.ttf');
}
@font-face {
  font-family: 'museo-md';
  src: url('./assets/fonts/museoSans/MuseoSans_500.otf');
}

@font-face {
  font-family: 'museo-bd';
  src: url('./assets/fonts/museoSans/MuseoSans_700.otf');
}

html {
  scroll-behavior: smooth;
}

*body {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'radikal';
}
.d-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font12 {
  font-size: 12px;
}

.font20 {
  font-size: 20px !important;
}

.font18 {
  font-size: 18px !important;
}

.font36 {
  font-size: 36px !important;
}

.font60 {
  font-size: 60px !important;
}

.font64 {
  font-size: 64px !important;
}

.font24 {
  font-size: 24px !important;
}
.font26 {
  font-size: 26px !important;
}

.font30 {
  font-size: 30px !important;
}

.font54 {
  font-size: 54px !important;
}

.font58 {
  font-size: 58px !important;
}

.font11 {
  font-size: 11px !important;
}

.font10 {
  font-size: 10px !important;
}

.font48 {
  font-size: 48px;
}
.font45 {
  font-size: 45px;
}
.text-grey {
  color: #7188a4 !important;
}
.font-light {
  font-weight: 300;
  font-family: 'radikal' !important;
}
.font-bold {
  font-weight: 600;
  font-family: 'publico' !important;
}
.font-museo-bd {
  font-weight: 600;
  font-family: 'museo-bd' !important;
}
.font-brown {
  font-family: 'radikal' !important;
}
.box-shadow {
  box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
}
.bg-white {
  background: white !important;
}
.bg-black {
  background-color: black;
}
.hidden {
  overflow: hidden !important;
}
.text-white {
  color: white !important;
}
.click {
  cursor: pointer;
}
textarea {
  resize: none;
}
.btn,
.form-control,
a,
span,
button,
summary {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.py-space {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.pt-space {
  padding-top: 20vh;
}
.pl-8 {
  padding-left: 8vw;
}
.py-10 {
  padding: 10vh 0;
}
.pt-10 {
  padding-top: 10vh;
}

.pl-8 {
  padding-left: 8vw;
}

.pr-8 {
  padding-right: 8vw;
}

.px-8 {
  padding-right: 8vw;
  padding-left: 8vw;
}

.border-radius {
  border-radius: 6px;
}
@media (max-width: 994px) {
  .font58 {
    font-size: 50px !important;
  }
  .font60 {
    font-size: 45px !important;
  }
  .sm-mt {
    margin-top: 2rem !important;
  }
  .sm-dflex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .sm-mt-none {
    margin-top: 0 !important;
  }
  .sm-pt-none {
    padding-top: 0 !important;
  }
  .sm-px-none {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .sm-ml-none {
    margin-left: 0 !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .sm-mb-none {
    margin-bottom: 0 !important;
  }
  .sm-mt-2 {
    margin-top: 2rem !important;
  }
  .sm-mb-2 {
    margin-bottom: 2rem !important;
  }
  .sm-dblock {
    display: block !important;
  }
  .sm-dnone {
    display: none !important;
  }
  .sm-d-none {
    display: none !important;
  }
  .sm-mx-8 {
    margin-left: 8vw;
    margin-right: 8vw;
  }
}

@media (min-width: 900px) {
  .lg-mr-0 {
    margin-right: 0 !important;
  }
  .lg-ml-0 {
    margin-left: 0 !important;
  }
}
.text-blue {
  color: #060954 !important;
}
.text-black {
  color: black !important;
}
.text-green {
  color: #8cfff1 !important;
}

.bg-green {
  background: #60cbbe !important;
}

.bg-blue {
  background: #060954 !important;
}

.bg-grey {
  background: #e2e2e6 !important;
}

.p-r {
  position: relative;
}
.logo {
  width: 50px;
}

.text-light-green {
  color: #b6d2e3 !important;
}
header {
  min-height: 100vh;
  background: url('./assets/img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

nav {
  .dropdown-menu {
    background: #060954;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05), 0 -4px 5px rgba(34, 48, 82, 0.05),
      -4px 4px 4px rgba(0, 0, 0, 0.05), inset -10px 0 0 #6467cc1c;
    .nav-link {
      color: #b6d2e3 !important;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-item {
    color: white;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: #060954;
  }
  .nav-link {
    color: #b6d2e3 !important;
    font-size: 18px;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.program-nav {
  .nav-link {
    color: #17162d !important;
    font-size: 18px;
  }
}

.program-header {
  min-height: 97vh;
  background: url('./assets/img/bgg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  @media (max-width: 770px) {
    background-position: left;
  }
  a {
    color: #060954;
  }
}
.each-course {
  cursor: pointer;
}
.courses-wrapper {
  .each-course {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05), 0 -4px 5px rgba(34, 48, 82, 0.05),
      -4px 4px 4px rgba(0, 0, 0, 0.05), inset -10px 0 0 #060954;
    border-radius: 5px;
    padding-right: 4rem;
    background: #fff;
    padding: 1rem;
    margin: 2rem 0;
    transition: all ease 0.3s;
    &:hover {
      transform: translate(0px, 10px);
    }
    p {
      font-size: 18px;
    }
  }
}
footer {
  a {
    color: #7188a4 !important;
  }
  .logo {
    width: 87px;
  }
  .footer-sign,
  .footer-course {
    background: url('./assets/img/bg14.png');
    background-size: cover;
    min-height: 42vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #060954;
    p {
      color: white;
      font-size: 18px;
      letter-spacing: 2px;
    }
    .lists {
      p {
        font-size: 15px;
      }
      min-height: 348px;
      padding: 2rem 4rem !important;
      .line-under {
        height: 3px;
        width: 100%;
        background: #8cfff1;
      }
    }
  }
}
.faq-wrapper {
  font-family: 'museo-md';
  .font-bold {
    font-family: 'museo-bd' !important;
    font-weight: 700;
  }
}
.about-wrapper {
  .big-letter {
    float: left;
    display: block;
    margin: 0em 0.215em 0 0;
    padding: 0.105em 0.29em 0.11em;
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: #60cbbe;
  }
  .purpose-wrapper {
    background: #060954;
    color: white;
  }
  .goals {
    min-height: 348px;
    padding: 2rem 4rem !important;
    .line-under {
      height: 3px;
      width: 170px;
      background: #8cfff1;
    }
  }
  .leaders {
    transition: all ease 0.3s;
    &:hover {
      transform: translate(0px, 10px);
    }
  }
}

.core-values {
  min-height: 298px;
  padding: 2rem 4rem !important;
  .line-under {
    height: 3px;
    width: 170px;
    background: #8cfff1;
  }
}
.add-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f1f4f3;
  .add-plus {
    color: #2c58cb;
    border: 2px solid #2c58cb;
    border-radius: 124px;
    height: 40px;
    width: 40px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
  }
}

.modal-body {
  .profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .file-picker {
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    .inputfile {
      display: none;
    }
  }
}
.modal {
  p {
    color: #484848;
  }
  select {
    font-size: 14px;
    color: #999999;
  }
  .common-input {
    background: #fcfcfc;
    border: 1px solid #ebebeb;
    border-radius: 25px;
    padding: 0.6rem 1rem;
    option {
      font-size: 14px;
      color: #999999;
    }
    &::placeholder {
      font-size: 14px;
      color: #999999;
    }
    &::-moz-placeholder {
      font-size: 14px;
      color: #999999;
    }
    &:focus,
    input:focus,
    .btn:active,
    .btn:focus,
    select {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
.course-duration {
  font-size: 12px;
  background: #60cbbe;
  color: white;
  border-radius: 4px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.feature-box {
  background: white;
  border: #b6d2e3 solid 1px;
  transition: all ease 0.3s;
  &:hover {
    -webkit-box-shadow: 5px 5px 0px 0px #e1eeff, 10px 10px 0px 0px #e1eeff,
      15px 15px 0px 0px #a1d8ff, 20px 20px 0px 0px #cae6ff,
      25px 25px 0px 0px #e1eeff, 2px 17px 26px 9px rgba(182, 210, 227, 0);
    box-shadow: 5px 5px 0px 0px #e1eeff, 10px 10px 0px 0px #e1eeff,
      15px 15px 0px 0px #a1d8ff, 20px 20px 0px 0px #cae6ff,
      25px 25px 0px 0px #e1eeff, 2px 17px 26px 9px rgba(182, 210, 227, 0);
  }
  img {
    width: 50px;
  }
}
.learn-img {
  object-fit: cover;
  height: 382px;
  width: 100%;
  max-width: 100%;
  @media (max-width: 770px) {
    height: 200px;
  }
}
.bg-bible {
  background: url('./assets/img/bible.jpg');
  background-size: cover;
  min-height: 50vh;
  background-position: center;
  color: white;
}
.stripe-checkout-wrapper {
  form {
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    min-height: auto !important;
    background: transparent !important;
  }
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
}
.cert-logo {
  border-radius: 50%;
}
.pt10vh {
  padding-top: 10vh;
}
.executive-image {
  height: fit-content;
}

@media (max-width: 1100px) {
  .iframe-responsive {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.7%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
.pagination {
  margin: auto;
  background: white;
  padding: 0 0.5rem;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  li {
    margin: 0 0.6rem;
    a {
      color: #787676;
    }
    &.active {
      background: linear-gradient(174.23deg, #060954 51.36%, #1cb4ae 117.17%);
      height: 35px;
      width: 35px;
      border-radius: 50%;
      align-items: center;
      display: flex;
      justify-content: center;
      a {
        color: white;
      }
    }
  }
}
.welcome-logo {
  width: 141px;
}
.calendar-wrapper,
.staffs-wrapper {
  min-height: 71vh;
  padding-top: 15vh;
}
.group-sessions-wrapper {
  .close {
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 0;
    z-index: 3;
  }
  .group-sessions {
    position: relative;
    cursor: pointer;

    &.purple {
      color: #4d4cac;
      background: #f6f4ff;
    }
    &.blue {
      color: #1070b7;
      background: #ecf6ff;
    }
    &.red {
      color: #f57861;
      background: #f6e5e9;
    }
    &.brown {
      background: rgba(130, 130, 130, 0.24);
      color: #4f4f4f;
    }
    &.green {
      color: #437b0b;
      background: #fefbf0;
    }
  }
}
.social-icon {
  font-size: 23px;
  margin-left: 12px;
}
