@font-face {
  font-family: 'museo-lt';
  src: url('../assets/fonts/museoSans/MuseoSans-300.otf');
}
.signIn-wrapper {
  min-height: 100vh;
  background: #060954;
  overflow: hidden;
  .sign-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .logo-wrapper {
    .login-logo {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
  footer {
    p,
    a {
      font-size: 11px;
      color: #b9b9b9 !important;
      font-family: 'Museo-bd';
    }
  }
  form {
    background-color: white;
    border-radius: 4px;
    min-height: 470px;
    a:hover {
      text-decoration: none;
    }
    &.signupForm {
      padding: 3rem;
      @media (max-width: 770px) {
        padding: 3rem 1.5rem;
      }
    }
  }
}

form {
  .form-group {
    position: relative;
    margin-bottom: 2rem;
    .form-control {
      background: #fcfcfc;
      border: 1px solid #ebebeb;
      border-radius: 30px;
      padding: 1.3rem 0.75rem;
      font-size: 14px;
      &::placeholder {
        font-size: 13px;
        font-family: 'Museo-lt';
        color: #979797;
      }
    }
    textarea.form-control {
      border-radius: 20px !important;
    }
    .input-icon {
      position: absolute;
      right: 13px;
      top: 7px;
    }
  }
  .login-nav {
    color: #171e40;
    opacity: 0.4;
    font-size: 32px;
    cursor: pointer;
    &.active {
      opacity: 1;
    }
  }
  .btn {
    width: 100%;
    padding: 0.8rem 0.7rem !important;
    font-size: 16px !important;
    border-radius: 30px;
    font-family: 'Museo-bd';
  }
}
.signGoogle {
  color: #263238;
  background: white;
  border-radius: 4px;
  display: flex;
}
.curve1 {
  position: absolute;
  top: -45px;
  right: 0;
  width: 120px;
  img {
    width: 100%;
  }
}
.curve2 {
  position: absolute;
  bottom: 0px;
  width: 135px;
  img {
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.Dropdown-root {
  background: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 30px;
  padding: 0.6rem 0.75rem;
  font-size: 13px;
  .Dropdown-control {
    background-color: transparent !important;
    border: none !important;
    color: #979797 !important;
    padding: 0 !important;
  }
  .Dropdown-arrow{
    right: 5px;
    top: 9px;
  }
}
